<template>
  <div class="about">
    <div class="left"></div>
    <div class="right">
      <p class="tips">关于我们</p>
      <div class="cont">
        关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案关于我们描述文案
      </div>
      <p class="tips">联系我们</p>
      <div class="address">
        <p>
          工作时间：上午8:00-12:00，下午2:00-6:00 <br> 
          电话：0551-82656919-801 18392391547 <br>
          传真：0551-82656919-801 <br>
          E-mail：qmhul@xjtu.edu.cn <br>
          邮编：240000 <br>
          地址：安徽省合肥市高新区云飞路1号天立泰科技园三楼
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.about {
  width: 1200px;
  margin: 20px auto 60px;
  display: flex;
  .left {
    width: 300px;
    height: 600px;
    background: url('../assets/basic/about_left.png');
    margin-right: 40px;
  }
  .right {
    flex: 1;
    .tips {
      position: relative;
      font-size: 24px;
      font-weight: 600;
      color: #222;
      padding-left: 24px;
      &::before {
        top: 4px;
        left: 0;
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 24px;
        background: #B30000;
      }
    }
    .cont {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 56px;
    }
    .address {
      >p {line-height: 48px;}
    }
  }
}
</style>